import React from "react"
import styled from "styled-components"
import heroLogoSrc from "../images/studio-face-logo.png"
import { Link, useStaticQuery, graphql } from "gatsby"
import { slide as Menu } from "react-burger-menu"

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 5em;
  display: ${({hideHeaderOnMobile}) => hideHeaderOnMobile ? 'none' : 'flex'};
  flex-direction: row;
  @media(min-width: 1000px) {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
    backdrop-filter: blur(20px);
  }
`

const HeaderLogoLink = styled(Link)`
  display: block;
  width: 100%;
  max-width: 6em;
  height: auto;
  align-self: center;
  margin-left: 1em;
`

const HeaderLogo = styled.img`
  display: block;
  width: 100%;
`

const Nav = styled.div`
  display: none;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  align-self: stretch;
  justify-self: flex-end;
  justify-content: flex-end;
  @media(min-width: 1000px) {
    display: flex;
  }
`

const StyledNavItem = styled(Link)`
  color: #fff;
  padding: 1em 0;
  margin: 0 1em;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.9em;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border-top: 0.2em solid transparent;
  border-bottom: 0.2em solid transparent;
  &:hover {
    color: #fb578a;
  }
  @media(max-width: 999px) {
    border-bottom-color: transparent !important;
  }
`

const MobileMenu = styled(Menu)`
  background: rgba(0,0,0,0.7);
  backdrop-filter: blur(20px);
`

const NavItem = ({to, children}) => {
  return <StyledNavItem to={to} activeStyle={{borderBottomColor: "#fb578a"}}>{children}</StyledNavItem>
}

export default function({hideHeaderOnMobile}) {
    const data = useStaticQuery(graphql`
      query HeaderQuery {
        allContentfulSiteSettings {
          edges {
            node {
              mainMenu {
                title
                slug
              }
            }
          }
        }
      }
    `)
    return <>
      <Header hideHeaderOnMobile={hideHeaderOnMobile}>
        <HeaderLogoLink to="/">
          <HeaderLogo src={heroLogoSrc} />
        </HeaderLogoLink>
        <Nav>
          <NavItem to="/">Etusivu</NavItem>
          <NavItem to="/mallit" partiallyActive>Mallit</NavItem>
          {data.allContentfulSiteSettings.edges[0].node.mainMenu.map(({title, slug}) =>
            <NavItem to={"/" + slug} partiallyActive>{title}</NavItem>
          )}
        </Nav>
      </Header>
      <MobileMenu right>
        <NavItem to="/">Etusivu</NavItem>
        <NavItem to="/mallit" partiallyActive>Mallit</NavItem>
        {data.allContentfulSiteSettings.edges[0].node.mainMenu.map(({title, slug}) =>
          <NavItem to={"/" + slug} partiallyActive>{title}</NavItem>
        )}
      </MobileMenu>
    </>
}
