import React from "react"
import styled from "styled-components"
import Header from "../components/header"
import Footer from "../components/footer"
import Helmet from "react-helmet"

const LayoutWrapper = styled.div`

`

export default function({children, hideHeaderOnMobile, isIndex}) {
    return <LayoutWrapper>
        <Helmet titleTemplate="Studio Face - %s" defaultTitle="Studio Face">
            <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, minimum-scale=1, maximum-scale=1" />
        </Helmet>
        <Header hideHeaderOnMobile={hideHeaderOnMobile} />
        {children}
        <Footer isIndex={isIndex} />
    </LayoutWrapper>
}